<bw-action-article
  *ngIf="partnerIntro$ | async as partnerIntro; else spinner">
  <div header-actions>
    <ui-nav-exit-back
      [trackingDetail]="{ event_name: 'lender_alternatives_intro' }"
      [skipActionEmit]="false"
      (exitActionHandler)="goBackToPartnerList()"
    ></ui-nav-exit-back>
  </div>
  <div pre-title>
    <div class="partner-logo" [style.background-image]="'url(' + cdnImgUrl + partnerIntro.imagePartner + '.png)'"></div>
  </div>
  <div header-title>
    <div class="flex mt-sm">
      <typography title-medium color="var(--primary-text)">
        <div [innerHTML]="partnerIntro.name | translate"></div>
      </typography>
    </div>
  </div>
  <div content-section>
    <typography color="var(--secondary-text)" style="max-width: 450px">
      <div [innerHTML]="partnerIntro.summary | translate"></div>
    </typography>

    <typography text-medium-semibold class="mb-sm mt-sm">
      {{ 'SUMMARY' | translate }}
    </typography>
    <typography color="var(--secondary-text)" style="max-width: 450px">
      <div [innerHTML]="partnerIntro.desc | translate"></div>
    </typography>
  </div>
  <div footer-actions class="mt-md">
    <button bw-button
        (click)="goToPartnerRedirectUrl(partnerIntro)">
      {{ 'CONTINUE_TO_CTA' | translate:{lenderName: partnerIntro.name|translate} }}
    </button>
  </div>
</bw-action-article>

<ng-template #spinner>
  <bw-spinner></bw-spinner>
</ng-template>
