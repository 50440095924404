/**
 * if api fields can only have a limited set of values, document them here
 */
export enum SurveyQuestionType {
  choice = 'choice',
  choiceMulti = 'choiceMulti',
}

export enum SavingsSource {
  ach = 'ach',
  payroll = 'payroll',
}

export enum SavingsScheduleType {
  biweekly = 'biweekly',
  monthly = 'monthly',
  everyPaycheck = 'everyPaycheck',
  alternatePaycheck = 'alternatePaycheck',
}

export enum SavingsSetupStatus {
  canceled = 'canceled',
  accountCreated = 'account-created',
  payrollSetup = 'payroll-setup',
  errorFboCreation = 'error-fbo-creation',
  errorCustodialCreation = 'error-custodial-creation',
}

export enum EligibleProduct {
  achSavings = 'achSavings',
  cashAdvance = 'cashAdvance',
  cashFlow = 'cashFlow',
  creditImprovement = 'creditImprovement',
  creditScore = 'creditScore',
  loan = 'loan',
  payrollSavings = 'payrollSavings',
  spending = 'spending',
  invite = 'invite',
}

export enum DesktopCardCaseType {
  ASK_ME_ANYTHING = 'Ask_Me_Anything',
  CASH_FLOW = 'Cash_Flow',
  CONTEST = 'PLS_Promo',
  CREDIT_CARD_REFI = 'Credit_Card_Refi',
  CREDIT_CRUSHER = 'Credit_Crusher',
  CREDIT_SCORE = 'Credit_Score',
  EMERGENCY_CASH = 'Emergency_Cash',
  HELPING_HANDS = 'HELPING_HANDS',
  MPAP = 'MPAP',
  SAVINGS = 'Emergency_Savings',
  SELF_LENDER = 'Self_Lender',
  SPENDING = 'Direct_Deposit_Account',
  STUDENT_LOAN_REFI = 'Student_Loan_Refi',
  WELCOME_VIDEO = 'Welcome_To_Brightside',
  LOAN_DASHBOARD = 'Loan_Dashboard',
  LENDER_ALTERNATIVES = 'Lender_Alternatives'
}

export enum DesktopCardType {
  DEFAULT = 'Default',
  INFO = 'Info',
  CTA = 'Cta',
}

export enum DesktopSegmentType {
  DEFAULT = 'Default',
  INFO = 'Info',
  CTA = 'Cta',
}

export enum DesktopCardStyle {
  FOCUSED = 'Focused',
  RIGHT = 'Right',
}

export enum DesktopCardSegmentDynamicType {
  BASIC = 'basic',
  CURRENCY = 'currency',
}

export enum DesktopCardCondition {
  DATA_PRESENT = 'DataPresent',
  NO_DATA_PRESENT = 'NoDataPresent',
  COMPLETE = 'Completed',
}

export enum TransferAccountType {
  SAVINGS = 'Savings',
  SPENDING = 'Spending',
  ACH = 'ACH',
}

//MW finsol.service LinkedBankStatus
export enum LinkedBankStatus {
  ACTIVE = 'active',
  CREDENTIALS_LOCKED = 'credentials-locked',
  INVALID_ROUTING_CREDS = 'invalid-routing-creds',
  LINK_VERIFY_DEPOSIT = 'link-verify-deposit',
  LINKED = 'linked',
  LINKING = 'linking',
}

export enum CardDisplayType {
  DEFAULT = 'Default',
  DEFAULT_INFO = 'DefaultInfo',
  ACTIVE = 'Active',
  ACTIVE_DEFAULT = 'ActiveDefault',
  ACTIVE_LOCKED = 'ActiveLocked',
}

export enum LinkedAccountType {
  SAVINGS = 'SAVINGS',
  CHECKING = 'CHECKING',
}

export enum AutosaveMode {
  DEFAULT = 'default',
  EDIT = 'edit',
}

export enum CustodialAccountStatus {
  ERROR = 'error',
  CREATING = 'creating',
  CREATED = 'created',
}

export enum SavingsSchedulePatchAction {
  UNPAUSE = 'unpause',
  PAUSE = 'pause',
  SKIP = 'skip',
  UPDATE = 'update',
  SKIP_PLANNED = 'skip_planned',
  UNSKIP_PLANNED = 'unskip_planned',
  UPDATE_PLANNED = 'update_planned',
}

export enum CreditSetupStatus {
  NOT_STARTED = 'NOT_STARTED',
  TERMS_ACCEPTED = 'TERMS_ACCEPTED',
  VERIFY_OTP = 'VERIFY_OTP',
  VERIFY_KBA = 'VERIFY_KBA',
  ENROLLED = 'ENROLLED',
  VERIFIED = 'VERIFIED',
  VERIFICATION_FAILED = 'VERIFICATION_FAILED',
  FULFILLED = 'FULFILLED',
  VERIFY_SMFA_TIMEOUT = 'VERIFY_SMFA_TIMEOUT',
  VERIFY_SMFA = 'VERIFY_SMFA',
  VERIFY_DIT = 'VERIFY_DIT',
}

export enum CreditSetupVerifyStatus {
  COMPLETE = 'COMPLETE',
  COMPLETE_UNCONFIRMED = 'COMPLETE_UNCONFIRMED',
  MORE_INFORMATION = "MORE_INFORMATION",
  ERROR_NO_RETRY = "ERROR_NO_RETRY",
  ERROR_ALLOW_RETRY = "ERROR_ALLOW_RETRY",
  ERROR_FATAL = "ERROR_FATAL"
}

export enum RequiredVerificationsEnums {
  LOCALSTORAGE = 'requiredVerifications',
  EMPLOYEEALIAS = 'unique_id',
  ACCOUNT = 'account',
  EMAIL = 'email',
  HOMEADDRESS = 'auto_address',
  FULLSSN = 'full_ssn',
  HUBCHANNEL ='RequiredVerificationsChannel'
}
