import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { EligibleProduct, MobileStateService } from '@brightside-web/desktop/data-access/shared';
import { map } from 'rxjs/operators';
import { SimpleGlobalModalService } from '@brightside-web/desktop/data-access/core-services';

@Injectable({
  providedIn: 'root',
})
export class HouseholdInviteGuard implements CanActivate {
  constructor(
    private _msService: MobileStateService,
    private _modalService: SimpleGlobalModalService,
    private _router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._canInviteHousehold();
  }

  private _canInviteHousehold(): Observable<boolean> {
    return this._msService.get().pipe(
      map((ms) => {
        if (ms.products?.includes(EligibleProduct.invite)) {
          return true;
        } else {
        this._modalService.createModal({
          eventName: 'household_invite_ineligible',
          title: 'HOUSEHOLD_INVITE_INELIGIBLE_TITLE',
          contentKey: 'HOUSEHOLD_INVITE_INELIGIBLE_DESC',
          displayCloseButton: true,
          isError: false,
          size: 'medium',
          ctaKey: 'CLOSE',
          ctaAction: () => {
            this._modalService.clearModal();
            this._router.navigate(['/home']);
          },
          closeAction:() => {
            this._modalService.clearModal();
            this._router.navigate(['/home']);
          }
        });
        return false;
        }
      })
    );
  }
}
