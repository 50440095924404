import { Component, Input } from '@angular/core';

export interface PillConfig {
  iconLeftName?: string;
  iconLeftColor?: string;
  iconRightName?: string;
  iconRightColor?: string;
  label: string;
  labelColor: string;
  labelStyle?: 'bold';
  backgroundColor?: string;
}

@Component({
  selector: 'bw-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss'],
})
export class PillComponent {
  @Input() pillConfig: PillConfig;
}
