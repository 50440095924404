import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

export interface ActionDetailsCardConfigInterface {
  labelImg?: string;
  title?: string;
  body?: string;
  bgColor?: string;
  selected?: boolean;
  label?: string;
  labelSub?: string;
  labelIcon?: string;
  iconButton?: string;
  iconButtonSize?: number;
  iconbuttonColor?: string;
  cta?: string;
  hideCta?: boolean;
  ctaString?: string;
  optionalIcon?: string;
  removeMinHeight?: boolean;
  removeMaxWidth?: boolean;
  marginBottom?: 'medium' | 'large';
  details?: ActionDetailsCardDetail[],
  data?: any
}

export interface ActionDetailsCardDetail {
  title: string;
  detail: string;
}

@Component({
  selector: 'bw-action-details-card',
  templateUrl: './action-details-card.component.html',
  styleUrls: ['./action-details-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionDetailsCardComponent implements OnInit {

  @Input() cardConfig!: ActionDetailsCardConfigInterface;
  @Output() ctaClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void { }

  emitCtaClicked() : void {
    this.ctaClicked.emit(true);
  }

}
