import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import {InjectedInformation} from "@brightside-web/micro/core/authorization";
import {DocumentType} from "@aws-amplify/core/dist/esm/libraryUtils";
import {Environment} from "@micro-core/environment";
import {BsAuthService} from "../Auth/bs-auth.service";
import { firstValueFrom, throwError } from 'rxjs';
import { error } from 'ng-packagr/lib/utils/log';
import { catchError } from 'rxjs/operators';


declare let Injected: InjectedInformation;
declare global {
  interface Window {
    Injected: InjectedInformation;
  }
}

export interface WrapperRestApiOptionsBase {
  headers?: any;
  params?: Record<string, string>;
  body?: DocumentType | FormData | any;
  withCredentials?: boolean;
  responseType?:string;
}

export interface RestApiOptionsExtended extends WrapperRestApiOptionsBase {
  [key: string]: any;
}
@Injectable({
  providedIn: 'root'
})
export class AwsApiWrapperService {

  unauthMsg = 'User is not authorized to access this resource with an explicit deny';

  constructor(
    private _http: HttpClient,
    private _env: Environment,
    private bsAuthService: BsAuthService
  ) { }

  handleError(err: HttpErrorResponse) {
    if (err.status === 403 && err.message === this.unauthMsg) {
      this.forceLogout()
    }
    return throwError(err);
  }

  async get(apiName: string, path: string, init?: RestApiOptionsExtended, responseType?:string): Promise<any> {
    const requestObj = await this._createRequestObj(apiName, init, responseType);
    if (!requestObj) {
      // if the user Auth is undefined then we don't have a user and can't call api-mobile
      // without throwing an error
      return false;
    }
    return this._http.get(
    `${this._env.awsmobile.endpoints[apiName].endpoint}${path}`,
      requestObj).pipe(catchError(this.handleError)).toPromise();
  }


  async put(apiName: string, path: string, init?: RestApiOptionsExtended): Promise<any> {
    const requestObj = await this._createRequestObj(apiName, init);
    if (!requestObj) {
      // if the user Auth is undefined then we don't have a user and can't call api-mobile
      // without throwing an error
      return false;
    }
    return this._http.put(
      `${this._env.awsmobile.endpoints[apiName].endpoint}${path}`,
      init?.body ?? {},
      requestObj).pipe(catchError(this.handleError)).toPromise();
  }

  async post(apiName: string, path: string, init?: RestApiOptionsExtended): Promise<any> {
    const requestObj = await this._createRequestObj(apiName, init);
    if (!requestObj) {
      // if the user Auth is undefined then we don't have a user and can't call api-mobile
      // without throwing an error
      return false;
    }
    return this._http.post(
      `${this._env.awsmobile.endpoints[apiName].endpoint}${path}`,
      init?.body ?? {},
      requestObj).pipe(catchError(this.handleError)).toPromise();
  }

  async patch(apiName: string, path: string, init?: RestApiOptionsExtended): Promise<any> {
    const requestObj = await this._createRequestObj(apiName, init);
    if (!requestObj) {
      // if the user Auth is undefined then we don't have a user and can't call api-mobile
      // without throwing an error
      return false;
    }
    return this._http.patch(
      `${this._env.awsmobile.endpoints[apiName].endpoint}${path}`,
      init?.body ?? {},
      requestObj).pipe(catchError(this.handleError)).toPromise();
  }

  async del(apiName: string, path: string, init?: RestApiOptionsExtended): Promise<any> {
    const requestObj = await this._createRequestObj(apiName, init);
    if (!requestObj) {
      // if the user Auth is undefined then we don't have a user and can't call api-mobile
      // without throwing an error
      return false;
    }
    return this._http.delete(
      `${this._env.awsmobile.endpoints[apiName].endpoint}${path}`,
      requestObj).pipe(catchError(this.handleError)).toPromise();
  }

  async _createRequestObj(apiName: string, init: RestApiOptionsExtended | undefined, responseType?:string): Promise<any> {
    if (!this._env.awsmobile.endpoints[apiName]) {
      throw Error('Api endpoint doesn\'t exist');
    }
    const dynamicHeaders = await this._getHeaders(apiName);
    if (dynamicHeaders.Authorization === 'undefined' && apiName === 'api-mobile') {
      // if the user Auth is undefined then we don't have a user and can't call api-mobile
      // without throwing an error so let's stop here
      return false;
    }

    const finalOptions:WrapperRestApiOptionsBase  = {headers: {...dynamicHeaders}};
    if (init?.queryParams) finalOptions['params'] = init.queryParams;
    if (init?.headers) finalOptions['headers'] = {...finalOptions['headers'], ...init.headers};
    if (responseType) finalOptions['responseType'] = responseType;

    return finalOptions;
  }

  async _getHeaders(apiName: string): Promise<any>{
    let headers = {}
    switch (apiName) {
      case 'api-mobile':
        headers = {
          Authorization: `Bearer ${await firstValueFrom(this.bsAuthService.getToken())}`,
          ...this.getStandardHeaders(),
        }
        break;
      case 'api-mobile-noauth':
        headers = {...this.getStandardHeaders()}
        break;
    }
    return headers;
  }

  getStandardHeaders = () => {
    const headers: { [key: string]: any } = {
      'Accept': 'application/json, text/plain, application/pdf, */*',
      'Content-Type': 'application/json',
      'X-BS-OS-Name': 'Desktop',
      'X-BS-Model': this.getBrowser(),
      'X-BS-Timezone': this.getTimeZone(),
      'X-BS-GmtOffset': `${new Date().getTimezoneOffset() / -60}`,
      'X-BS-DeviceId': this.getDeviceId(),
    };

    return headers;
  };

  getDeviceId = () => {
    let deviceId = window.localStorage['DEVICE_ID'];
    if (!deviceId) {
      deviceId = this.generateUUID();
      window.localStorage['DEVICE_ID'] = deviceId;
    }
    return deviceId;
  };

  getBrowser = () => {
    const userAgent = navigator.userAgent;
    const browserMapping: { [key: string]: { validMatch: string[]; extraChecks?: Function[] } } = {
      Opera: {
        validMatch: ['Opera', 'OPR'],
      },
      Chrome: {
        validMatch: ['Chrome'],
      },
      Safari: {
        validMatch: ['Safari'],
      },
      Firefox: {
        validMatch: ['Firefox'],
      },
      IE: {
        validMatch: ['MSIE'],
        extraChecks: [() => Object.keys(document).includes('documentMode')],
      },
    };

    return (
      Object.keys(browserMapping).filter((key: string) => {
        const checkDetails = browserMapping[key];

        if (checkDetails.validMatch.some((browserName) => userAgent.includes(browserName))) {
          //If there are extra checks, make sure they all return true
          if (checkDetails.extraChecks && !checkDetails.extraChecks.every((checkFunc) => checkFunc())) {
            return false;
          }

          return true;
        }

        return false;
      })[0] || 'Unknown'
    );
  };

  getTimeZone = () => {
    const formatParts = Intl.DateTimeFormat('en', { timeZoneName: 'short' }).format().split(' ');

    return formatParts[formatParts.length - 1];
  };

  generateUUID = () => {
    let d = new Date().getTime(); //Timestamp
    let d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16; //random number between 0 and 16
      if (d > 0) {
        //Use timestamp until depleted
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line no-bitwise
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        //Use microseconds since page-load if supported
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line no-bitwise
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line no-bitwise
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  };

  forceLogout() {
    this.bsAuthService.logout();
  }
}


//
// const awsOptions = {
//   API : {
//     REST: {
//
//       headers: async () => ({
//         Authorization: `${(await fetchAuthSession()).tokens?.idToken?.toString()}`,
//         ...getStandardHeaders(),
//       }),
//
//     }
//   }
// }
