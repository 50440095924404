import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, takeWhile, tap } from 'rxjs';
import { ExternalLendersService, PartnerDetails } from '@brightside-web/desktop/feature/external-lenders';
import { Environment } from '@micro-core/environment';
import { FirebaseService } from '@brightside-web/desktop/data-access/shared';

@Component({
  selector: 'brightside-web-external-lenders-partner',
  templateUrl: './external-lenders-partner.component.html',
  styleUrls: ['./external-lenders-partner.component.scss'],
})
export class ExternalLendersPartnerComponent {

  partnerIntro$: Observable<PartnerDetails | undefined> = this._externalLendersService.currentPartnerDetails;
  cdnImgUrl: string;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _externalLendersService: ExternalLendersService,
    private _env: Environment,
    private _analytics: FirebaseService
  ) {
    this.cdnImgUrl = this._env.cdnUrl + 'images/desktop/';

    this.partnerIntro$.pipe(
      takeWhile(partner => !partner, true),
      tap(partner => {
        if (partner) {
          this._analytics.logEvent('lender_alternatives_intro_shown', {
            value: partner.key,
            url: partner.url
          });
        }
      })
    ).subscribe();

    this._route.paramMap
      .pipe(
        tap((params) => {
          this._externalLendersService.getLenderDetails(params.get('lenderKey') || '');
        })
      )
      .subscribe();
  }

  goToPartnerRedirectUrl(partner: PartnerDetails) {
    this._analytics.logEvent('lender_alternatives_intro_redirect', {
      value: partner.key,
      url: partner.url
    });
    window.open(partner.url, '_blank');
  }

  goBackToPartnerList() {
    this._router.navigate(['/lender-alternatives']);
  }
}
