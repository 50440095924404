import { Component, ElementRef, Input } from '@angular/core';

export interface LabelCellConfig {
  subtitleColor?: string;
  subtitle?: string;
  titleColor?: string;
  title: string;
  detail?: string;
  eyebrow?: boolean;
  leftIconName?: string;
  leftIconColor?: string;
  border?: boolean;
  disabled?: boolean;
}

@Component({
  selector: 'bw-label-cell',
  templateUrl: './label-cell.component.html',
  styleUrls: ['./label-cell.component.scss'],
})
export class LabelCellComponent {

  @Input() labelCellConfig: LabelCellConfig;

  constructor() {}


}
