import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { from, map, Observable, of } from 'rxjs';
import { DesktopCardCaseType, MobileStateService } from '@brightside-web/desktop/data-access/shared';

@Injectable({
  providedIn: 'root',
})
export class ExternalLendersGuard implements CanActivate, CanLoad {
  constructor(private _router: Router, private _mobileStateSvc: MobileStateService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasLenderAlternativesCard();
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasLenderAlternativesCard();
  }

  hasLenderAlternativesCard(): Observable<boolean> {
    return this._mobileStateSvc.get().pipe(
      map((mobileState) => {
        const activateCards = mobileState.activatedCards;
        const lenderAlternativeCard = activateCards?.find((card) => card.case_type === DesktopCardCaseType.LENDER_ALTERNATIVES);
        if (lenderAlternativeCard) {
          return true;
        } else {
          this._router.navigate(['home']);
          return false;
        }
      }),
    );
  }
}
