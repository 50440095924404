<div class="bw-pill"
     [style.background-color]="pillConfig.backgroundColor ?? 'var(--blue-10)'"
     [ngClass]="{'icon-left': pillConfig.iconLeftName, 'icon-right' : pillConfig.iconRightName}"
>
  <div class="flex-row">
    <bw-icon *ngIf="pillConfig.iconLeftName"
             size="20"
             [iconName]="pillConfig.iconLeftName"
             [color]="pillConfig.iconLeftColor">
    </bw-icon>
    <ng-container [ngSwitch]="pillConfig.labelStyle">
      <typography *ngSwitchCase="'bold'" text-small-semibold [color]="pillConfig.labelColor">{{ pillConfig.label }}</typography>
      <typography *ngSwitchDefault text-small-regular [color]="pillConfig.labelColor">{{ pillConfig.label }}</typography>
    </ng-container>
    <bw-icon *ngIf="pillConfig.iconRightName"
             size="20"
             [iconName]="pillConfig.iconRightName"
             [color]="pillConfig.iconRightColor">
    </bw-icon>
  </div>
</div>
