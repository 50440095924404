import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

export interface HeaderLabelCellConfig {
  lottie?: AnimationOptions;
  leftTitle: string;
  leftSubtitle?: string;
  rightTitle?: string;
  rightSubtitle?: string;
  rightIconName?: string;
  rightIconColor?: string;
}

@Component({
  selector: 'bw-header-label-cell',
  templateUrl: './header-label-cell.component.html',
  styleUrls: ['./header-label-cell.component.scss'],
})
export class HeaderLabelCellComponent implements AfterViewInit {
  @Input() labelCellConfig: HeaderLabelCellConfig;
  @ViewChild('contentWrapper') contentWrapper!: ElementRef;
  @Output() clickEvent?: EventEmitter<void> = new EventEmitter<void>();

  hasContent = false;
  hasClickListener = false;

  lottieOptions: AnimationOptions = {
    path: 'assets/animations/your-animation.json' // Path to your Lottie JSON file
  };

  lottieStyles = {
    width: '24px',
    height: '24px'
  };

  onAnimationCreated(animation: any): void {
    console.log('Lottie animation created:', animation);
  }

  ngAfterViewInit(): void {
    // Check if contentWrapper has child nodes
    this.hasContent = this.contentWrapper.nativeElement.childNodes.length > 0;
    this.hasClickListener = this.clickEvent ? this.clickEvent?.observers.length > 0 : false;
  }

  onContainerClick(): void {
    if (this.hasClickListener) {
      this.clickEvent?.emit();
    }
  }
}
