<div class="label-cell"
     [ngClass]="{'disabled': labelCellConfig.disabled, 'border': labelCellConfig.border, 'eyebrow': labelCellConfig.eyebrow}">
  <div *ngIf="labelCellConfig.leftIconName" class="label-cell-icon-left mr-xs">
    <bw-icon iconName="{{labelCellConfig.leftIconName}}" color="{{labelCellConfig.leftIconColor}}"></bw-icon>
  </div>
  <div class="label-cell-label-wrapper">
    <typography
      class="label-cell-subtitle"
      [ngClass]="{'text-medium-semibold': !labelCellConfig.eyebrow, 'text-xsmall-regular': labelCellConfig.eyebrow}"
      [color]="labelCellConfig.subtitleColor"
    >
      {{labelCellConfig.subtitle}}
    </typography>
    <typography
      class="label-cell-title"
      [ngClass]="{'text-medium-semibold': labelCellConfig.eyebrow, 'text-xsmall-regular': !labelCellConfig.eyebrow}"
      [color]="labelCellConfig.titleColor"
    >
      {{labelCellConfig.title}}
    </typography>
  </div>
  <div class="label-cell-icon">
    <typography class="text-medium-regular" color="var(--primary-text)" *ngIf="labelCellConfig.detail">{{labelCellConfig.detail}}</typography>
    <bw-icon iconName="chevron_right"></bw-icon>
  </div>
</div>
