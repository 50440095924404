import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[bwClick]'
})
export class EnhancedClickDirective {

  @Output() bwClick = new EventEmitter<any[]>();
  @HostBinding('attr.tabindex') tabindex = '0';
  @HostBinding('attr.role') role = 'button';
  @HostBinding('class.bwClick') bwClickClass = true;

  // Host listener for click event
  @HostListener('click', ['$event'])
  handleClick(event: Event): void {
    this.bwClick.emit([]);
  }

  // Host listener for "Enter" key press
  @HostListener('keydown.enter', ['$event'])
  handleEnter(event: KeyboardEvent): void {
    event.preventDefault(); // Prevent default behavior for "Enter"
    this.bwClick.emit([]);
  }

  // Host listener for "Space" key press
  @HostListener('keydown.space', ['$event'])
  handleSpace(event: KeyboardEvent): void {
    event.preventDefault(); // Prevent default behavior for "Space"
    this.bwClick.emit([]);
  }
}
