import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {
  AuthGuard,
  DisplayLoaderComponent,
  GuidGuard,
  OnboardingCompleteGuard,
  ProductVerificationGuard,
  RequiredVerificationGuard,
  SequentialGuard,
  SequentialGuardMode,
} from '@brightside-web/shared/desktop';

import { VerifyComponent } from './verify/verify.component';

//NOTE: all canLoad guards here must also be applied as canActivate guards in the modules
// see https://stackoverflow.com/questions/43933595/angular2-authguard-not-working-when-using-browser-navigation-buttons
import { BsCacheService, FeatureFlagKillSwitchGuard } from '@brightside-web/desktop/data-access/core-services';
import { ReviewAppGuard } from './review-app.guard';
import { WaitlistComponent } from './waitlist/waitlist.component';

import { LayoutStyle } from './routing.service';

import {
  BREADCRUMB_LABEL as INVITE_BREADCRUMB_LABEL,
  ROUTES as INVITE_ROUTES,
  ROUTES_PATH as INVITE_ROUTES_PATH,
} from './routes/invite';

import { HasTermsUpdateGuard } from '@brightside-web/micro/terms-update';
import { KnownFeatureFlags } from '@brightside-web/desktop/data-access/shared';
import { SharedDisplayWaitingComponent } from '@brightside-web/shared/display';
import { MaintenanceGuard, MaintenanceProductType } from '@brightside-web/desktop/feature/maintenance';
import { ExternalLendersGuard } from '@brightside-web/desktop/feature/external-lenders';

const microAppRoutes = [
  {
    path: 'financialsnapshot',
    data: { breadcrumb: { skip: true } },
    loadChildren: () => import('@brightside-web/micro/financial-snapshot').then((m) => m.MicroFinancialSnapshotModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'helping-hands',
    data: { product: MaintenanceProductType.HelpingHands, breadcrumb: { skip: true } },
    canLoad: [AuthGuard, OnboardingCompleteGuard, MaintenanceGuard],
    canActivate: [AuthGuard, MaintenanceGuard],
    loadChildren: () => import('@brightside-web/micro/helping-hands').then((m) => m.MicroHelpingHandsModule),
  },
  {
    path: 'mpap',
    data: { product: MaintenanceProductType.Mpap, breadcrumb: { skip: true } },
    canLoad: [AuthGuard, OnboardingCompleteGuard, MaintenanceGuard],
    canActivate: [AuthGuard, MaintenanceGuard],
    loadChildren: () => import('@brightside-web/micro/mpap').then((m) => m.MicroMpapModule),
  },
  {
    path: 'terms-update',
    data: { product: MaintenanceProductType.TermsUpdate, breadcrumb: { skip: true }, appDisplayStyle: LayoutStyle.FULL_SCREEN },
    canLoad: [AuthGuard, HasTermsUpdateGuard, MaintenanceGuard],
    loadChildren: () => import('@brightside-web/micro/terms-update').then((m) => m.MicroTermsUpdateModule),
  },
  {
    //Please pass the forwarding path in the query param like: ?pageOnForwardCtaPath=<route>
    path: 'loader',
    component: DisplayLoaderComponent,
    data: {
      breadcrumb: { skip: true },
      pageConfig: {
        fbPage: 'loader',
        fbCategory: 'pls-loader',
        pageOnForwardCtaPath: [],
      },
    },
  },
  {
    path: 'financialsnapshot-loader',
    component: DisplayLoaderComponent,
    data: {
      breadcrumb: { skip: true },
      pageConfig: {
        fbPage: 'loader',
        fbCategory: 'financial-snapshot',
        pageOnForwardCtaPath: ['financialsnapshot', 'intro'],
      },
    },
  },
];

const accountRoutes: Routes = [
  {
    path: 'accounts',
    canLoad: [AuthGuard, OnboardingCompleteGuard],
    canActivate: [AuthGuard, OnboardingCompleteGuard],
    children: [
      {
        path: 'savings',
        loadChildren: () =>
          import('@brightside-web/desktop/feature/savings-account').then((m) => m.DesktopFeatureSavingsAccountModule),
        canLoad: [MaintenanceGuard],
        canActivate: [MaintenanceGuard],
        data: {
          breadcrumb: 'SAVINGS',
          product: MaintenanceProductType.Savings,
        },
      },
      {
        path: 'spending',
        loadChildren: () =>
          import('@brightside-web/desktop/feature/spending-account').then((m) => m.DesktopFeatureSpendingAccountModule),
        canLoad: [MaintenanceGuard],
        canActivate: [MaintenanceGuard],
        data: {
          breadcrumb: 'SPENDING',
          product: MaintenanceProductType.Spending,
        },
      },
      {
        path: 'external',
        loadChildren: () =>
          import('@brightside-web/desktop/feature/external-accounts').then((m) => m.DesktopFeatureExternalAccountsModule),
        canLoad: [MaintenanceGuard],
        canActivate: [MaintenanceGuard],
        data: {
          product: MaintenanceProductType.External,
        },
      },
      {
        path: 'transfer-funds',
        loadChildren: () =>
          import('@brightside-web/desktop/feature/new-transfer-funds').then((m) => m.DesktopFeatureNewTransferFundsModule),
        canLoad: [MaintenanceGuard],
        canActivate: [MaintenanceGuard],
      },
      {
        path: 'loans',
        loadChildren: () =>
          import('@brightside-web/desktop/feature/loans').then((m) => m.DesktopFeatureLoansModule),
        canLoad: [MaintenanceGuard],
        canActivate: [MaintenanceGuard],
        data: {
          product: MaintenanceProductType.Loans,
        },
      },
    ],
  },
];


const routes: Routes = [
  ...microAppRoutes,
  ...accountRoutes,
  {
    path: 'lender-alternatives',
    loadChildren: () =>
      import('@brightside-web/desktop/feature/external-lenders').then((m)=>m.DesktopFeatureExternalLendersModule),
    canLoad: [AuthGuard, OnboardingCompleteGuard, ExternalLendersGuard, FeatureFlagKillSwitchGuard],
    canActivate: [AuthGuard, OnboardingCompleteGuard, ExternalLendersGuard, FeatureFlagKillSwitchGuard],
    data: {
      featureFlagInfo: {
        flag: KnownFeatureFlags.ENABLELENDERALTERNATIVES
      }
    }
  },
  {
    path: 'maintenance',
    loadChildren: () =>
      import('@brightside-web/desktop/feature/maintenance').then((m)=>m.DesktopFeatureMaintenanceModule),
  },
  {
    path: 'chathistory',
    loadChildren: () =>
      import('@brightside-web/desktop/feature/chat-history').then((m)=> m.DesktopFeatureChatHistoryModule),
    canLoad: [AuthGuard, OnboardingCompleteGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'eligibility',
    loadChildren: () =>
      import('@brightside-web/desktop/feature/user-eligibility').then((m) => m.DesktopFeatureUserEligibilityModule),
  },
  {
    path: 'registration',
    loadChildren: () => import('@brightside-web/desktop/feature/registration').then((m) => m.DesktopFeatureRegistrationModule),
  },
  {
    path: 'onboarding',
    loadChildren: () => import('@brightside-web/desktop/feature/onboarding').then((m) => m.DesktopFeatureOnboardingModule),
    canLoad: [RequiredVerificationGuard, AuthGuard],
    canActivate: [AuthGuard],
    data: { appDisplayStyle: LayoutStyle.FULL_SCREEN },
  },
  {
    path: 'credit',
    loadChildren: () => import('@brightside-web/desktop/feature/credit').then((m) => m.DesktopFeatureCreditModule),
    canLoad: [AuthGuard, MaintenanceGuard],
    canActivate: [AuthGuard, MaintenanceGuard],
    data: { product: MaintenanceProductType.Credit, breadcrumb: { label: 'Home', routeInterceptor: () => '/home' } },
  },
  {
    path: 'credit-setup',
    loadChildren: () => import('@brightside-web/desktop/feature/credit-setup').then((m) => m.DesktopFeatureCreditSetupModule),
    canLoad: [AuthGuard, MaintenanceGuard],
    canActivateChild: [FeatureFlagKillSwitchGuard, ProductVerificationGuard],
    canActivate: [AuthGuard, MaintenanceGuard],
    data: {
      breadcrumb: { label: 'Home', routeInterceptor: () => '/home' },
      featureFlagInfo: {
        flag: KnownFeatureFlags.CREDITSETUP,
      },
      product: MaintenanceProductType.Credit
    },
    resolve: {
      appDisplayStyle: 'CreditLayoutResolver',
    },
  },
  {
    path: 'home',
    loadChildren: () => import('@brightside-web/desktop/feature/home').then((m) => m.DesktopFeatureHomeModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard, SequentialGuard],
    data: {
      breadcrumb: 'Home',
      appDisplayStyle: LayoutStyle.DEFAULT,
      guards: [GuidGuard, OnboardingCompleteGuard],
      guardsRelation: SequentialGuardMode.AND,
    },
  },
  {
    //https://netbasal.com/give-your-modals-url-address-with-auxiliary-routes-in-angular-eb76497c0bca
    path: 'settings',
    outlet: 'modal',
    loadChildren: () => import('@brightside-web/desktop/feature/settings').then((m) => m.DesktopFeatureSettingsModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'ways-we-can-help',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () => import('@brightside-web/desktop/feature/wwch').then((m) => m.DesktopFeatureWwchModule),
    data: { breadcrumb: 'Ways we can help' },
  },
  {
    path: 'verification',
    loadChildren: () => import('@brightside-web/desktop/feature/verification').then((m) => m.DesktopFeatureVerificationModule),
  },
  {
    path: 'program',
    canLoad: [AuthGuard, OnboardingCompleteGuard],
    loadChildren: () => import('@brightside-web/desktop/feature/programs').then((m) => m.DesktopFeatureProgramsModule),
  },
  {
    path: 'survey',
    loadChildren: () =>
      import('@brightside-web/desktop/feature/survey-questionnaire').then((m) => m.DesktopFeatureSurveyQuestionnaireModule),
    canLoad: [AuthGuard, OnboardingCompleteGuard, MaintenanceGuard],
    canActivate: [AuthGuard, OnboardingCompleteGuard, MaintenanceGuard],
    data: {product: MaintenanceProductType.Survey}
  },
  {
    path: 'pls',
    loadChildren: () =>
      import('@brightside-web/desktop/feature/prize-link-savings').then((m) => m.DesktopFeaturePrizeLinkSavingsModule),
    canLoad: [AuthGuard, OnboardingCompleteGuard, MaintenanceGuard],
    canActivate: [AuthGuard, OnboardingCompleteGuard, MaintenanceGuard],
    data: {product: MaintenanceProductType.Contest}
  },
  {
    path: 'contest-legal/sweepstakes-amoe',
    loadComponent: () => import('@brightside-web/desktop/feature/prize-link-savings')
      .then((c) => c.SweepstakesAmoeComponent),
    data: { appDisplayStyle: LayoutStyle.PUBLIC_ACCESS }
  },
  {
    path: 'find-money',
    loadChildren: () => import('@brightside-web/desktop/feature/find-money').then((m) => m.DesktopFeatureFindMoneyModule),
    canLoad: [AuthGuard, OnboardingCompleteGuard,MaintenanceGuard],
    canActivate: [AuthGuard, OnboardingCompleteGuard,MaintenanceGuard],
    data: {product: MaintenanceProductType.FindMoney}
  },
  {
    path: INVITE_ROUTES_PATH,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    children: INVITE_ROUTES,
    data: { breadcrumb: INVITE_BREADCRUMB_LABEL },
  },
  { path: 'sign_in', redirectTo: 'registration/sign_in' },
  { path: 'get_started', redirectTo: 'registration/create-account' },
  { path: 'verify', component: VerifyComponent },
  { path: 'waitlist', component: WaitlistComponent, data: { appDisplayStyle: LayoutStyle.PUBLIC_ACCESS } },
  {
    path: 'review-app',
    canActivate: [ReviewAppGuard],
    component: DisplayLoaderComponent,
    data: { appDisplayStyle: LayoutStyle.PUBLIC_ACCESS },
  },
  {
    path: 'setupcredit',
    redirectTo: 'credit-setup/creation',
  },
  {
    path: 'savings' /* the default card route ? */,
    redirectTo: 'home/savings',
  },
  {
    path: 'contestintro',
    redirectTo: 'pls/contest-intro',
  },
  {
    path: 'loans/dashboard',
    redirectTo: 'accounts/loans/dashboard'
  },
  {
    path: 'authenticating',
    component: SharedDisplayWaitingComponent,
    data: {
      config: { pageHideBackButton: true },
    },
  },
  { path: '**', redirectTo: 'registration/create-account' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  providers: [
    {
      provide: 'CreditLayoutResolver',
      useFactory: creditLayoutResolverFactory,
    },
  ],
})
export class AppRoutingModule {}

export function creditLayoutResolverFactory() {
  // This will return a function that returns a promise
  return () =>
    BsCacheService.getItemStatic('ONBOARDING_FLOW_ACTIVE').then((active: any) =>
      active === true ? LayoutStyle.FULL_SCREEN : LayoutStyle.DEFAULT
    );
}
