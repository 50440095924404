import { Component, OnInit } from '@angular/core';
import { ExternalLendersService, PartnerDetails } from '@brightside-web/desktop/feature/external-lenders';
import { map, Observable } from 'rxjs';
import { ActionDetailsCardConfigInterface } from '@brightside/brightside-ui';
import { TranslateService } from '@ngx-translate/core';
import { Environment } from '@micro-core/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseService } from '@brightside-web/desktop/data-access/shared';

@Component({
  selector: 'brightside-web-external-lenders-intro',
  templateUrl: './external-lenders-intro.component.html',
  styleUrls: ['./external-lenders-intro.component.scss'],
})
export class ExternalLendersIntroComponent implements OnInit {

  externalPartners$: Observable<ActionDetailsCardConfigInterface[] | undefined>;
  cdnImgUrl: string;
  partnersArray: string[] = [];
  partnersString = '';

  constructor(
    private _externalLendersService: ExternalLendersService,
    private _translateSvc: TranslateService,
    private _env: Environment,
    private _router: Router,
    private _route: ActivatedRoute,
    private _analytics: FirebaseService
  ) {
    this.cdnImgUrl = this._env.cdnUrl + 'images/app/';
    this.externalPartners$ = this._externalLendersService.externalPartners.pipe(
      map((partners: PartnerDetails[] | undefined) => {
          if(partners) {
            const partnersMap = partners.map((partner) => this.mapToCardConfig(partner));
            this.partnersString = this.partnersArray.join(',');
            this._analytics.logEvent('lender_alternatives_shown', {value: this.partnersString})
            return partnersMap;
          } else {
            return undefined;
          }
        }
      )
    );
  }

  ngOnInit() {
    this._externalLendersService.getLenders();
    this._externalLendersService.resetLenderDetails();
  }

  private mapToCardConfig(partner: PartnerDetails): ActionDetailsCardConfigInterface {
    this.partnersArray.push(partner.key);
    return {
      title: this._translateSvc.instant(partner.name.toUpperCase()),
      labelImg: this.cdnImgUrl + partner.image + '.png',
      cta: partner.url,
      ctaString: this._translateSvc.instant('LEARN_MORE'),
      details: partner.details.map(detailGroup => ({
        title: this._translateSvc.instant(detailGroup[0].toUpperCase()),
        detail: this._translateSvc.instant(detailGroup[1].toUpperCase())
      })),
      data: partner
    };
  }

  onCtaClicked(partnerData: PartnerDetails) {
    if(partnerData) {
      // this._analytics.logEvent('lender_alternatives_click', {
      //   type: partnerData.key,
      // });
      this._router.navigate([partnerData.key], {relativeTo: this._route });
    }
  }
}
