<div class="section-card mb-sm">
  <typography
    *ngIf="header"
    text-medium-semibold
    color="var(--secondary-text)"
    class="mb-sm"
  >{{header}}</typography>

  <ng-container *ngIf="wrappedContentTemplate">
    <ng-container *ngTemplateOutlet="wrappedContentWrapper"></ng-container>
  </ng-container>
  <ng-container *ngIf="altContentTemplate">
    <ng-container *ngTemplateOutlet="altContentWrapper"></ng-container>
  </ng-container>
</div>

<ng-template #wrappedContentWrapper>
  <div class="section-card__wrapper" [ngClass]="borderType">
    <ng-container *ngTemplateOutlet="wrappedContentTemplate"></ng-container>
    <ng-container *ngIf="footerContentTemplate">
      <ng-container *ngTemplateOutlet="footerContentWrapper"></ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #altContentWrapper>
    <ng-container *ngTemplateOutlet="altContentTemplate"></ng-container>
</ng-template>

<ng-template #footerContentWrapper>
  <ng-container *ngTemplateOutlet="footerContentTemplate"></ng-container>
</ng-template>
