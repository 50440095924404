import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExternalLendersIntroComponent } from './external-lenders-intro/external-lenders-intro.component';
import { ExternalLendersPartnerComponent } from './external-lenders-partner/external-lenders-partner.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedDesktopModule } from '@brightside-web/shared/desktop';
import { BrightsideUiModule } from '@brightside/brightside-ui';
import { TranslateModule } from '@ngx-translate/core';
import { MicroUiMoleculeModule } from '@micro-ui/molecule';

const ROUTES: Routes = [
  {
    path: '',
    component: ExternalLendersIntroComponent,
  },
  {
    path: ':lenderKey',
    component: ExternalLendersPartnerComponent,
  }
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(ROUTES),
    SharedDesktopModule,
    BrightsideUiModule,
    TranslateModule,
    MicroUiMoleculeModule,
  ],
  declarations: [
    ExternalLendersIntroComponent,
    ExternalLendersPartnerComponent,
  ],
})
export class DesktopFeatureExternalLendersModule {}
