<bw-action-article maxWidth="516px">
  <ui-nav-exit-back
    header-actions
    [trackingDetail]="{ event_name: 'lender_alternatives' }"></ui-nav-exit-back>

  <div pre-title>
    <typography title-medium>{{ 'LENDER_ALTERNATIVE_TITLE' | translate }}</typography>
  </div>
  <div header-title>
    <typography class="mt-sm mb-sm">{{ 'LENDER_ALTERNATIVE_DESC' | translate }}</typography>
  </div>
  <div content-section>
    <div *ngIf="(externalPartners$ | async) as partners; else loading">
      <bw-action-details-card
        *ngFor="let partnerConfig of partners"
        [cardConfig]="partnerConfig"
        (ctaClicked)="onCtaClicked(partnerConfig.data)"
      ></bw-action-details-card>
    </div>
  </div>
  <div footer-top>
    <typography
      text-xsmall-semibold
      color="var(--secondary-text)"
      class="mt-sm">{{ 'LENDER_ALTERNATIVE_DISCLAIMER_TITLE' | translate }}</typography>
    <typography text-xsmall-regular class="disclaimer">
      {{ 'LENDER_ALTERNATIVE_DISCLAIMER_DESC' | translate }}
    </typography>
  </div>
</bw-action-article>
<ng-template #loading>
  <bw-spinner class="mb-md"></bw-spinner>
</ng-template>
