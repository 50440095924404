import {
  CreditSetupStatus,
  CreditSetupVerifyStatus,
  DesktopCardCaseType,
  DesktopCardCondition,
  DesktopCardStyle,
  DesktopCardType,
  DesktopCardSegmentDynamicType,
  EligibleProduct,
  LinkedBankStatus,
  SavingsScheduleType,
  SavingsSetupStatus,
  SavingsSource,
  SurveyQuestionType,
  TransferAccountType,
  LinkedAccountType,
  AutosaveMode,
  CustodialAccountStatus,
  SavingsSchedulePatchAction,
  DesktopSegmentType,
} from './api-enums';
import { AnalyticsAttributesV4 } from './firebase.service';

export interface ApiResponse {
  statusCode: number;
  message: string;
  requiredVerification?: RequiredVerification;
}

export interface ApiErrorResponse {
  statusCode: number;
  message: string;
  errorMessage?: string;
}


export interface AxiosResponse {
  status: number;
}

//https://healthydollar.atlassian.net/wiki/spaces/EN/pages/576323619/Mobile+State
// if we ever fetch the survey/cards directly from a service instead of from mobile state, the interfaces can be moved out of here
// see MW MobileState interface
export interface MobileStateResponse {
  baasProvider?: string;
  sf_record_type?: string;
  savingsAccount?: boolean;
  onboardingComplete?: number;
  survey?: Survey;
  supportNumber: string;
  customPassword?: boolean;
  requiredVerification?: RequiredVerification;
  requiredVerifications?: RequiredVerification[];
  chatProvider?: string;
  availability?: AvailabilityHours;
  externalSavings?: boolean;
  //  account?: SavingsAccount; // DO NOT USE THIS
  savingsSetup?: SavingsSetup;
  products?: EligibleProduct[];
  activatedCards?: DesktopCard[];
  creditScore?: boolean;
  cards?: DesktopCard[];
  home?: MobileStateHome;
  explore?: MobileStateExplore;
  redirect?: string;
  flags?: { [key: string]: boolean };
  campaigns?: { [key: string]: MSCampaign };
  spendingAccount?: boolean;
  notifications?: unknown[];
  user_guid?: string;
  contests?: MsContest[];
  intercom_external_id?:string;
}

export interface AnalyticsActions {
  tapped?: string;
  shown?: string;
  started?: string;
  completed?: string;
  skipped?: string;
}

export interface MSCampaign extends Campaign {
  path?: string;
}

export interface Campaign {
  cta?: string;
  ctaKey?: string;
  image: string;
  ctaPath: string;
  secondaryCtaPath?: string;
  secondaryCta?: string;
  secondaryCtaKey?: string;
  key: string;
  title?: string;
  titleKey?: string;
  textBody?: string;
  textBodyKey?: string;
  textBodyAlign?: 'center' | 'left' | 'right'
  textBodyNoWrap?: boolean;
  isCreditIntro?: boolean;
}

export interface MsContest {
  drawingDate: string;
  id: number;
  isClientParticipating: boolean;
  didClientParticipate: boolean;
  name: string;
  resultsViewed: boolean;
}

export interface SavingsSetup {
  status: SavingsSetupStatus;
}

export interface SavingsStatementsResponse extends BaseSavingsAccount {
  first_statement_ts: string; // format: MM/YYYY;
  latest_statement_ts: string; // format: MM/YYYY;
}

export interface MobileStateHome {
  waysWeCanHelp: DesktopCard[];
  financialTools: DesktopCard[];
}

export interface MobileStateExplore {
  waysWeCanHelp: DesktopCard[];
  moreForYouToExplore: DesktopCard[];
}

export interface MobileStateActionRequest {
  key: string;
  action: MobileStateAction;
}

export interface AvailabilityHours {
  between: string;
}

export interface RequiredVerification {
  type: string;
  fullSsn?: Last4Ssn;
  status?: VerificationStatus;
  eligibility_field?: EligibilityFieldsObject;
}

export interface EligibilityFieldsInterface {
  elective_products?: string[];
  eligibility_fields: EligibilityFieldsObject[];
}

export interface EligibilityFieldsObject {
  name: string;
  api_parameter_name: string;
  unique_identifier_flag?: boolean;
  value?: string;
  not_replayable?:boolean;
  sub_fields?: EligibilityFieldsObject[];
}

export enum VerificationStatus {
  STARTED = 'started',
  COMPLETED = 'completed',
}

export interface Last4Ssn {
  last4: string;
}

export interface FullSsnRequest {
  ssn: string;
}

export interface Survey {
  questions: SurveyQuestion[];
}

export interface SurveyQuestion {
  questionOrder: number;
  id: string;
  choices: string[];
  field_type: SurveyQuestionType;
  field_label: string;
  instructions?: string;
}

export enum MobileStateAction {
  CLOSED = 'closed',
}

//MW finsol.service LinkedBank
export interface LinkedBank {
  id: number;
  status: LinkedBankStatus;
  type: string; // we only support type of routing now TODO make enum
  bank_name: string;
  last_four: string;
  account_class: string; // TODO make enum
  create_ts: string;
  bank_code?: string;
  balance_verifiable: boolean;
  bank_logo?: string;
  micro_meta?: LinkedBankMicro | null;
}

export interface LinkedBankMicro {
  is_resend_available: boolean;
  micro_deposit_initiated_ts: string;
  remaining_verification_attempts: number;
  sent_micro_deposit_attempts: number;
}

export interface BaseSavingsAccount {
  balance: number;
  available_balance: number;
  // do NOT use the status field; mobile-state savingsSetup tells us the correct account statuses for account creation
  // unknown how API/UX handles locked accounts
  status: string; // TODO make enum
  // do NOT use the source field; not only is the data misleading but we ONLY support payroll autosavings at this time
  // need to use product eligibility (for new) and transfer_schedule.source (for existing)
  // if not eligible for payrollSavings product (dont allow create)
  // if transfer_schedule.source not payroll (dont allow edit)
  source?: SavingsSource;
  monthly_withdrawals_remaining: number | null;
}

// see MW finsol SavingsAccountResponse interface
export interface SavingsAccount extends BaseSavingsAccount {
  banks: LinkedBank[];
  total_rewards_balance: number;
  autoSaveMatchReward: any; // desktop doesnt support rewards yet
  pay_frequency: string; // this could be undefined but we don't yet support non-payroll/non-employee in desktop
  name: string;
  next_deposit_amount?: number | null;
  next_deposit_date?: string | null;
  schedule_type?: SavingsScheduleType | null;
  schedule_paused?: string | null; // this is a date field
  active_status: string; //this is deprecated do not use it
  linked_account_status?: string; // should not use this, use banks[] instead
  is_first_schedule?: boolean;
  last_four_accnum: string;
}

// see https://healthydollar.atlassian.net/wiki/spaces/EN/pages/727843066/Savings+API#GET-Savings-Transactions-[inlineExtension]
export enum SavingsTransactionCategory {
  WITHDRAWAL = 'withdrawal',
  DEPOSIT = 'deposit',
  REWARD = 'reward'
}

// see MW finsol SavingsTransaction interface
export interface SavingsTransaction {
  label?: string; // undefined for skipped, TODO enum
  id: number | string;
  amount: number;
  is_credit: boolean;
  is_skipped: boolean;
  source?: any;
  estimated_arrival_date?: string | null;
  transaction_date?: string;
  status: string; //TODO enum
  type: string; // TODO enum
  category: SavingsTransactionCategory;
  client_display?: string; // undefined for skipped TODO enum
  skip_planned?: boolean; // used for skipped
  cutoff_date?: string;
  next_transfer_date?: string;
  description?: string;
}

export interface SavingsAch extends BaseSavingsAccount {
  routing_number: string;
  account_number: string;
}

export interface SavingsScheduleCreateRequest {
  schedule_type: SavingsScheduleType;
  next_transfer_date: string;
  amount: number;
  source: SavingsSource;
}

export interface SavingsSchedulePatchRequest extends SavingsScheduleCreateRequest {
  action: SavingsSchedulePatchAction;
}

export interface SavingsScheduleResponse extends ApiResponse {
  linkedCompletely: boolean;
  errorMessage?: string;
}

export interface SavingsPayrollScheduleResponse {
  schedule: string[];
}

// see https://healthydollar.atlassian.net/wiki/spaces/EN/pages/727843066/Savings+API#Get-Savings-Goal-Progress
export interface SavingsGoal {
  name: string;
  targetAmount: number;
  currentAmount: number;
  percentCompleted: number;
  status: string; // enum? unused
  id: number; // unused
  message: string; //unused
}

export interface DesktopCard {
  vendor?: string;
  processing?: boolean;
  case_type?: DesktopCardCaseType;

  closeable?: boolean;
  expandable?: boolean;

  segments?: DesktopCardSegment[];

  key?: string;
  state?: string;

  type?: DesktopCardType;
  style?: DesktopCardStyle;

  cta?: string;
  ctaPath?: string;

  icon?: string;
  graphic?: string;

  title?: string;
  text?: string;
  titleKey?: string;
  textKey?: string;
  subText?: string;

  hidden?: boolean;

  analytics?: AnalyticsActions;
  footer?: DesktopCardFooter;
}

export interface DesktopCardFooter {
  title: string;
  rightImage?: string;
  leftImage?: string;
  ctaPath?: string;
  style?: string;
}

export interface DesktopCardSegment {
  icon: string;
  title: string;
  subText: string;
  ctaPath: string;

  type: DesktopSegmentType;
  style: DesktopCardStyle;
  condition: DesktopCardCondition;

  /* These don't come from service and our desktop only */
  dynamicValue?: string | number;
  dynamicType?: DesktopCardSegmentDynamicType;
}

export interface DesktopCardAction {
  code?: string;
  label: string;
  route?: string;
}

//MW TransferParticipant
export interface TransferAccountBase {
  type: TransferAccountType;
  id?: number;
}
//MW TransferAccount
export interface TransferAccountDisplay extends TransferAccountBase{
  name: string;
  balance: number;
  //TODO logo?
  lastFour: string;
}
//MW Transfer
export interface TransferRequest {
  to: TransferAccountBase;
  from: TransferAccountBase;
  amount: number;
  transfer_date: string;
  transfer_reason?: string;
  transfer_reason_key?: string;
}

export interface LinkedAccountRequestBody {
  account_class: LinkedAccountType;
  account_num: string;
  routing_num: string;
}

export interface LinkedAccountResponse extends ApiResponse {
  linkedCompletely: boolean;
  errorMessage?: string;
}

export interface LinkedAccountVerifyRequestBody {
  bankId: number;
  amounts: (string | null | undefined)[];
}

export interface LinkedAccountVerifyResponse extends ApiResponse {
  linkedCompletely: boolean;
  errorMessage?: string;
}

export interface Question {
  instructions_copy?: string;
  id: string;
  choices: any[];
  instructions?: string;
  field_label: string;
  field_type: QuestionType;
  questionOrder: number;
  style?: any;
}

export enum QuestionType {
  CHOICE = 'choice',
  CHOICE_MULTI = 'choiceMulti',
}
//TODO move non-api-contracts to another place, for example shared-autosave module
/**
 * This allows for a lot of helper methods and can reduce a lot of duplicated code.
 *
 */
export class BasicComponentConfig {
  constructor(config: any = {}) {
    this.fbPage = config.fbPage || '';
    this.fbSubCategory = config.fbSubCategory || '';
    this.fbCategory = config.fbCategory || '';
    this.fbEventName = config.fbEventName || '';
    this.pageTitle = config.pageTitle || '';
    this.pageTitleImage = config.pageTitleImage || '';
    this.pageSubTitle = config.pageSubTitle || '';
    this.pageTitleKey = config.pageTitleKey || '';
    this.pageTitle = config.pageTitle || '';
    this.pageSubTitle = config.pageSubTitle || '';
    this.pageSubTitleKey = config.pageSubTitleKey || '';
    this.pageBodyText = config.pageBodyText;
    this.pageBodyTextKey = config.pageBodyTextKey;
    this.pageCtaLabel = config.pageCtaLabel || '';
    this.pageCtaLabelKey = config.pageCtaLabelKey || '';
    this.pageCtaEventName = config.pageCtaEventName || '';
    this.pageSecondaryCtaLabel = config.pageSecondaryCtaLabel || '';
    this.pageSecondaryCtaLabelKey = config.pageSecondaryCtaLabelKey || '';
    this.pageStepperActiveIndex = config.pageStepperActiveIndex || 0;
    this.pageOnBackCtaPath = config.pageOnBackCtaPath || [];
    this.pageOnForwardCtaPath = config.pageOnForwardCtaPath || [];
    this.pageOnForwardSecondaryCtaPath = config.pageOnForwardSecondaryCtaPath || [];
    this.bridgeExitOverride = config.bridgeExitOverride || '';
  }

  fbPage: string;
  fbSubCategory: string;
  fbCategory: string;
  fbEventName?: string;

  pageTitle?: string;
  pageTitleImage?: string;
  pageTitleKey?: string;
  pageSubTitle?: string;
  pageSubTitleKey?: string;
  pageBodyText?: string;
  pageBodyTextKey?: string;
  pageCtaLabel?: string;
  pageCtaLabelKey?: string;
  pageCtaEventName?: string;
  pageSecondaryCtaLabel?: string;
  pageSecondaryCtaLabelKey?: string;

  pageStepperActiveIndex?: number;
  pageOnBackCtaPath: string[];
  pageOnForwardCtaPath: string[];
  pageOnForwardSecondaryCtaPath?: string[];
  bridgeExitOverride?: string;
  /**
   * this defaults to view details for the firebase analytics, but can be overridden and extended as needed.
   *
   * @param action the action to fire, defaults to "" if not provided. only needed for click events.h
   * @param merge any extra attributes to pass into the firebase event.
   */

  //TODO: convert to pipe config | analyticEventName:'shown' returns config.fbEventName + _'shown'

  analyticsAttributes(
    action: string = '',
    merge?: { [key: string]: string },
    useCtaEvent: boolean = false
  ): AnalyticsAttributesV4 {
    return <AnalyticsAttributesV4>{
      event_name: this.fbEventName,

      ...merge,
    };
  }
}

export class Settings extends BasicComponentConfig {
  constructor(config: any = {}) {
    super(config);
    this.analytics = config.analytics || null;
    if (this.analytics != null) {
      console.log(`v4 analytic setup: [ shown: ${this.analytics.shown} ]`);
    }
    this.pageHideBackButton = config.pageHideBackButton || false;
    this.pageOnForwardCtaData = config.pageOnForwardCtaData || {};
  }

  analytics?: AnalyticsActions;
  pageOnForwardCtaData?: { [key: string]: any };
  pageHideBackButton?: boolean;
}

export class QuestionsSettings extends Settings {
  constructor(config: any = {}) {
    super(config);
    this.surveyType = config.surveyType || '';
    this.questions = config.questions || [];
  }
  surveyType: string;
  questions: Question[];
}

export class AutosaveComponentConfig extends BasicComponentConfig {
  constructor(config: any) {
    super(config);
    this.mode = config.mode || AutosaveMode.DEFAULT;
    this.pageAutosaveAmount = config.pageAutosaveAmount;
    this.pageStepperActiveIndexForSkipped = config.pageStepperActiveIndexForSkipped || -1;
  }

  mode: AutosaveMode;
  pageStepperActiveIndexForSkipped?: number;
  pageAutosaveAmount?: number;
}

export interface PayrollAccountResponse {
  status: CustodialAccountStatus;
}
export interface PayrollScheduleResponse {
  schedule: string[];
}

export interface CreditSetupResponse extends ApiResponse {
  reasonCode?: string;
  status: CreditSetupStatus;
  kbaFailedAttempts?: number;
  remainingSMFAAttempts?: number;
  isKBAAllowed?: boolean;
  smfaTimeTimeoutInMinutes?: number;
  kbaQuiz?: CreditSetupKbaQuiz;
  features?: string[];

}

export interface CreditSetupVerifyResponse {
  apiResponse: CreditSetupResponse | null;
  verifyStatus: CreditSetupVerifyStatus;
}

export interface CreditSetupKbaRequest {
  type: string;
  answers: CreditSetupKbaQuestionAnswer[];
}

export interface CreditSetupKbaQuiz {
  questions: CreditSetupKbaQuizQuestion[];
}

export interface CreditSetupKbaQuizQuestion {
  field_label: string;
  field_type: string;
  id: string;
  questionOrder: number;
  choices: string[];
  answerIdMapping: any; //ToDo: give it correct type
}

export interface CreditSetupKbaQuestionAnswer {
  questionId: number;
  answerId: number;
}

export interface CreditSummary {
  creditScore: CreditScore;
  scoreHistory?: CreditScore[];
  factors: CreditFactor[];
}
export interface CreditScore {
  score: number;
  reportDate: number;
  creditScoreChange: string;
  scoreRange: string;
}
export interface CreditFactor {
  label: string;
  value: string;
  descriptionText: string;
  improvementText: string;
}

export interface CreditSubscription {
  statusCode: number;
  status: string;
}

export interface GooglePredictionsArray {
  predictions: GoogleAddressResult[];
  status: string;
}

export interface GoogleAddressResult {
  structured_formatting: GoogleAddressResultObject;
  place_id: string;
}

export interface GoogleAddressResultObject {
  main_text: string;
  secondary_text: string;
  place_id: string;
}

export interface GooglePlaceDetails {
  result: GooglePlaceDetailsResults;
  status: string;
}

export interface GooglePlaceDetailsResults {
  address_components: GooglePlaceAddressComponent[];
}

export interface GooglePlaceAddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface BadgeIDInterface {
  status: string;
  response: ApiResponse;
}

export interface IPAPIComInterface {
  status: string;
  latitude: number;
  longitude: number;
}

export interface NotificationInterface {
  icon?: string;
  desktopSupported?: boolean;
  action: {type:string};
  halfsheet: HalfsheetInterface;
  text: string;
  id?: string;
}

export interface HalfsheetInterface {
  id: string;
  title: string;
  subText: string;
}

export interface SpendingAccount {
  name: string;
  balance: number;
  dda_create_ts: string; // MM-DD-YYYY
  cards: string[];
  last_four_accnum: string;
}

