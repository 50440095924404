<div class="header-label-cell" [class.has-content]="hasContent">
  <div
    *ngIf="hasClickListener; else noClick"
    (bwClick)="onContainerClick()"
    class="header-label-cell-container">
    <div class="header-label-cell-label-wrapper">
      <typography
        class="header-label-cell-title"
        color="var(--primary-text)">
        {{labelCellConfig.leftTitle}}
      </typography>
      <typography
        class="header-label-cell-subtitle"
        color="var(--secondary-text)" >
        {{labelCellConfig.leftSubtitle}}
      </typography>
    </div>
    <div class="header-label-cell-icon">
      <div *ngIf="labelCellConfig.rightTitle" class="header-label-cell-label-wrapper">
        <typography
          class="header-label-cell-title"
          color="var(--primary-text)">
          {{labelCellConfig.rightTitle}}
        </typography>
        <typography
          class="header-label-cell-subtitle"
          color="var(--secondary-text)" >
          {{labelCellConfig.rightSubtitle}}
        </typography>
      </div>
      <div *ngIf="labelCellConfig.rightIconName" class="label-cell-icon ml-xs">
        <bw-icon iconName="{{labelCellConfig.rightIconName}}" color="{{labelCellConfig.rightIconColor}}"></bw-icon>
      </div>
    </div>
  </div>
  <div #contentWrapper>
    <ng-content></ng-content>
  </div>
</div>

<ng-template #noClick>
  <div
    class="header-label-cell-container">
    <div class="header-label-cell-label-wrapper">
      <typography
        class="header-label-cell-title"
        color="var(--primary-text)">
        {{labelCellConfig.leftTitle}}
      </typography>
      <typography
        class="header-label-cell-subtitle"
        color="var(--secondary-text)" >
        {{labelCellConfig.leftSubtitle}}
      </typography>
    </div>
    <div class="header-label-cell-icon">
      <div *ngIf="labelCellConfig.rightTitle" class="header-label-cell-label-wrapper">
        <typography
          class="header-label-cell-title"
          color="var(--primary-text)">
          {{labelCellConfig.rightTitle}}
        </typography>
        <typography
          class="header-label-cell-subtitle"
          color="var(--secondary-text)" >
          {{labelCellConfig.rightSubtitle}}
        </typography>
      </div>
      <div *ngIf="labelCellConfig.rightIconName && !labelCellConfig.lottie" class="label-cell-icon ml-xs">
        <bw-icon iconName="{{labelCellConfig.rightIconName}}" color="{{labelCellConfig.rightIconColor}}"></bw-icon>
      </div>
      <div *ngIf="labelCellConfig.lottie && !labelCellConfig.rightIconName" class="animation-container">
        <ng-lottie
          [options]="labelCellConfig.lottie"
          [styles]="lottieStyles"
          (animationCreated)="onAnimationCreated($event)">
        </ng-lottie>
      </div>
    </div>
  </div>
</ng-template>
