import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonComponent } from './button/button.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ThemeComponent } from './theme/theme.component';
import { InputComponent } from './input/input.component';
import { TopbarComponent } from './topbar/topbar.component';
import { ListComponent } from './list/list.component';
import { ModalComponent } from './modal/modal.component';
import { PanelComponent } from './panel/panel.component';
import { ParagraphComponent } from './typography/paragraph/paragraph.component';
import { CaptionComponent } from './typography/caption/caption.component';

import { AnchorComponent } from './anchor/anchor.component';
import { IconComponent } from './icon/icon.component';
import { PhoneFormComponent } from './input/phone-form/phone-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreatePasswordComponent } from './input/create-password/create-password.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { RadioComponent } from './radio/radio.component';
import { ReactiveCheckboxComponent } from './checkbox/reactive-checkbox/reactive-checkbox.component';
import { ToastComponent } from './toast/toast.component';
import { BackComponent } from './back/back.component';
import { CellComponent } from './cell/cell.component';
import { MenuComponent } from './menu/menu.component';
import { TabsComponent } from './tabs/tabs.component';
import { RouterModule } from '@angular/router';
import { ActionArticleComponent } from './layout/templates/action-article/action-article.component';
import { PageComponent } from './layout/templates/page/page.component';
import { BillboardArticleComponent } from './layout/templates/billboard-article/billboard-article.component';
import { InputGroupComponent } from './input/input-group/input-group.component';
import { VideoComponent } from './video/video.component';
import { NgxMaskModule } from './utils/ngx-mask/ngx-mask.module';
import { IConfig } from './utils/ngx-mask/config';
import { TypographyComponent } from './typography/typography/typography.component';
import { ScrollIntoViewDirective } from './utils/scroll-into-view.directive';
import { TextColorDirective } from './utils/text-color.directive';
import { ToggleComponent } from './toggle/toggle.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';

import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgStreamingModule } from '@videogular/ngx-videogular/streaming';
import { LoadingBarModule } from '@ngx-loading-bar/core';

import { StepsComponent } from './steps/steps.component';
import { SafeStylePipe } from './utils/safe-style.pipe';
import {
  CardBannerComponent,
  CardBodyComponent,
  CardComponent,
  CardFooterComponent,
  CardHeaderComponent,
} from './card/card.component';
import { BlockComponent } from './block/block.component';

import { NgxCurrencyModule } from 'ngx-currency';
import { CurrencyInputComponent } from './input/currency-input/currency-input.component';
import { CurrencySelectComponent } from './input/currency-select/currency-select.component';
import { EmptyStateComponent } from './layout/templates/empty-state/empty-state.component';
import { CellListComponent } from './cell-list/cell-list.component';
import { AccordionComponent } from './accordion/accordion.component';
import { AccordionContentDirective, AccordionItemDirective } from './accordion/accordion-item/accordion-item.directive';
import { TogglePipe } from './accordion/toggle.pipe';
import { IconBoundsComponent } from './icon/icon-bounds/icon-bounds.component';
import { NavComponent } from './nav/nav.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { HighlightPipe } from './autocomplete/highlight.pipe';
import { TrackerComponent } from './tracker/tracker.component';
import { ActionCellComponent } from './cell/action-cell/action-cell.component';
import { AvatarComponent } from './avatar/avatar.component';
import { ReelsComponent } from './layout/templates/reels/reels.component';
import { CardLtComponent } from './card/card-lt/card-lt.component';
import { TxCellComponent } from './cell/tx-cell/tx-cell.component';
import { ListItemDetailsComponent } from './list/list-item-details/list-item-details.component';
import { HeaderComponent } from './header/header.component';
import { BuiRowComponent } from './grid/row/bui-row/bui-row.component';
import { BuiColumnComponent } from './grid/column/bui-column/bui-column.component';
import { BuiTileComponent, TileCardComponent, TileListItemComponent } from './containers/bui-tile/bui-tile.component';
import { ClickStyleDirective } from './utils/clickable.directive';
import { ActionCardComponent } from './action-card/action-card.component';
import { CopyCellComponent } from './copy-cell/copy-cell.component';
import { SectionCardComponent } from './section-card/section-card.component';
import { NewCurrencySelectComponent } from './currency-select/new-currency-select.component';

import { UiSwitchModule } from 'ngx-ui-switch';
import { NumbersOnlyDirective } from './utils/numbers-only.directive';
import { SurveySelectorComponent } from './survey-selector/survey-selector.component';
import { TextareaComponent } from './textarea/textarea.component';
import { TranslateModule } from '@ngx-translate/core';
import { PillComponent } from './pill/pill.component';
import { EnhancedClickDirective } from './directives/enhanced-click.directive';
import { LabelCellComponent } from './label-cell/label-cell.component';
import { ActionDetailsCardComponent } from './action-details-card/action-details-card.component';
import { HeaderLabelCellComponent } from './cell/header-label-cell/header-label-cell.component';
import { LottieComponent } from 'ngx-lottie';

export type InputMaskOptions = Partial<IConfig> | (() => Partial<IConfig>);
export const ngxMaskOptions: InputMaskOptions = {};

@NgModule({
  imports: [
    CommonModule,
    NgxMaskModule.forRoot(ngxMaskOptions),
    ReactiveFormsModule,
    RouterModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    VgStreamingModule,
    NgxCurrencyModule,
    LoadingBarModule,
    FormsModule,
    UiSwitchModule,
    TranslateModule,
    LottieComponent,
  ],
  declarations: [
    ButtonComponent,
    CardComponent,
    CardHeaderComponent,
    CardBodyComponent,
    CardFooterComponent,
    CardBannerComponent,
    SpinnerComponent,
    ThemeComponent,
    InputComponent,
    TopbarComponent,
    ListComponent,
    ModalComponent,
    PanelComponent,
    ParagraphComponent,
    CaptionComponent,
    AnchorComponent,
    IconComponent,
    PhoneFormComponent,
    CreatePasswordComponent,
    CheckboxComponent,
    RadioComponent,
    ReactiveCheckboxComponent,
    ToastComponent,
    BackComponent,
    CellComponent,
    CellListComponent,
    MenuComponent,
    TabsComponent,
    ActionArticleComponent,
    PageComponent,
    BillboardArticleComponent,
    InputGroupComponent,
    TypographyComponent,
    ScrollIntoViewDirective,
    TextColorDirective,
    ToggleComponent,
    VideoComponent,
    StepsComponent,
    SafeStylePipe,
    BlockComponent,
    CurrencyInputComponent,
    CurrencySelectComponent,
    EmptyStateComponent,
    AccordionComponent,
    AccordionItemDirective,
    AccordionContentDirective,
    TogglePipe,
    IconBoundsComponent,
    ProgressBarComponent,
    NavComponent,
    AutocompleteComponent,
    HighlightPipe,
    TrackerComponent,
    ActionCellComponent,
    AvatarComponent,
    ReelsComponent,
    CardLtComponent,
    TxCellComponent,
    ListItemDetailsComponent,
    HeaderComponent,
    BuiRowComponent,
    BuiColumnComponent,
    BuiTileComponent,
    TileCardComponent,
    TileListItemComponent,
    ClickStyleDirective,
    ActionCardComponent,
    CopyCellComponent,
    SectionCardComponent,
    NewCurrencySelectComponent,
    NumbersOnlyDirective,
    SurveySelectorComponent,
    TextareaComponent,
    PillComponent,
    EnhancedClickDirective,
    LabelCellComponent,
    ActionDetailsCardComponent,
    HeaderLabelCellComponent,
  ],
  exports: [
    ButtonComponent,
    CardComponent,
    CardHeaderComponent,
    CardBodyComponent,
    CardFooterComponent,
    CardBannerComponent,
    SpinnerComponent,
    ThemeComponent,
    InputComponent,
    TopbarComponent,
    ListComponent,
    ModalComponent,
    PanelComponent,
    ParagraphComponent,
    CaptionComponent,
    AnchorComponent,
    IconComponent,
    PhoneFormComponent,
    CreatePasswordComponent,
    CheckboxComponent,
    RadioComponent,
    ReactiveCheckboxComponent,
    ToastComponent,
    BackComponent,
    CellComponent,
    CellListComponent,
    MenuComponent,
    TabsComponent,
    ActionArticleComponent,
    PageComponent,
    BillboardArticleComponent,
    InputGroupComponent,
    TypographyComponent,
    ScrollIntoViewDirective,
    TextColorDirective,
    ToggleComponent,
    VideoComponent,
    StepsComponent,
    SafeStylePipe,
    BlockComponent,
    CurrencyInputComponent,
    CurrencySelectComponent,
    EmptyStateComponent,
    AccordionComponent,
    AccordionItemDirective,
    AccordionContentDirective,
    IconBoundsComponent,
    ProgressBarComponent,
    NavComponent,
    AutocompleteComponent,
    HighlightPipe,
    TrackerComponent,
    ActionCellComponent,
    AvatarComponent,
    ReelsComponent,
    CardLtComponent,
    TxCellComponent,
    ListItemDetailsComponent,
    HeaderComponent,
    BuiRowComponent,
    BuiColumnComponent,
    BuiTileComponent,
    TileCardComponent,
    TileListItemComponent,
    ClickStyleDirective,
    ActionCardComponent,
    CopyCellComponent,
    SectionCardComponent,
    NewCurrencySelectComponent,
    SurveySelectorComponent,
    NumbersOnlyDirective,
    TextareaComponent,
    PillComponent,
    EnhancedClickDirective,
    LabelCellComponent,
    ActionDetailsCardComponent,
    HeaderLabelCellComponent,
  ],
  providers: [],
})
export class BrightsideUiModule {}
