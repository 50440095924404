import { Injectable } from '@angular/core';
import { DesktopCard } from '@brightside-web/desktop/data-access/shared';
import {
  BsApiService,
  GenericResponseResult,
  SimpleGlobalModalService
} from '@brightside-web/desktop/data-access/core-services';
import { BehaviorSubject, catchError, Observable, of, Subject, take, tap } from 'rxjs';
import { Router } from '@angular/router';

export interface PartnerDetails {
  key: string;
  name: string;
  caption: string;
  desc: string;
  summary: string;
  tagline?: string;
  image: string;
  imagePartner: string;
  url: string;
  details: string[][];
}

export interface ExternalLenderResponse {
  payload: {partners: PartnerDetails[]};
}

@Injectable({
  providedIn: 'root'
})
export class ExternalLendersService {

  apiName = 'api-mobile';

  private _externalPartners: BehaviorSubject<PartnerDetails[] | undefined> = new BehaviorSubject<PartnerDetails[] | undefined>(undefined);
  public readonly externalPartners: Observable<PartnerDetails[] | undefined> = this._externalPartners.asObservable();

  private _currentPartnerDetails: BehaviorSubject<PartnerDetails | undefined> = new BehaviorSubject<PartnerDetails | undefined>(undefined);
  public readonly  currentPartnerDetails: Observable<PartnerDetails | undefined> = this._currentPartnerDetails.asObservable();

  private readonly _endpoint = '/external-partner?type=lender-alternatives';
  private _lenderDetailAttempts = 0;
  private _lenderDetailMaxAttempts = 10;


  constructor(
    private _bsApiSvc: BsApiService,
    private _router: Router,
    private _modalService: SimpleGlobalModalService,
  ) { }

  getLenders(lenderKey?:string)  {
    this._bsApiSvc.get<ExternalLenderResponse>(this.apiName, this._endpoint).pipe(
      take(1),
      tap(lenders => {
        if (lenders.payload.partners && lenders.payload.partners.length > 0) {
          this._externalPartners.next(lenders.payload.partners);
          if (lenderKey) {
            this.getLenderDetails(lenderKey);
          }
        }
      }),
    ).subscribe({
      error: err => {
        this._modalService.createGenericErrorModal('external_lenders', ()=>{
          this.getLenders(lenderKey);
          this._modalService.clearModal();
        });
      }
    });
  }

  getLenderDetails(lenderKey: string) {
    this._lenderDetailAttempts++;

    if (this._lenderDetailAttempts > this._lenderDetailMaxAttempts) {
      this.lenderNotFound();
      return;
    }

    if (this._externalPartners.value && this._externalPartners.value.length > 0) {
      const details = this._externalPartners.value.find(partner => partner.key === lenderKey);

      if (details) {
        this._currentPartnerDetails.next(details);
        this._lenderDetailAttempts = 0;
      } else {
        this.lenderNotFound();
      }
    } else {
      setTimeout(() => this.getLenders(lenderKey), 100);
    }
  }

  lenderNotFound() {
    this._router.navigate(['home']);
  }

  async groomCardData(cardData: DesktopCard): Promise<DesktopCard> {
    return cardData;
  }

  resetLenderDetails() {
    this._currentPartnerDetails.next(undefined);
  }
}
