import { Injector } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  CONTROL_DOB,
  CONTROL_EMAIL,
  CONTROL_FNAME,
  CONTROL_LAST_FOUR,
  CONTROL_LNAME,
  CONTROL_PHONE,
  DisplayCardsComponent,
  DisplayFormComponent,
  DisplayFormProcessResponse, HouseholdInviteGuard
} from '@brightside-web/shared/desktop';
import { InviteHouseholdCache, InviteReferralType, InviteService, InviteType } from '@brightside-web/desktop/data-access/shared';
import { ToastService } from '@brightside/brightside-ui-services';

import { Observable } from 'rxjs';
import * as libphonenumber from 'libphonenumber-js/max';

const sendConfirmToast = (toastService: ToastService) => {
  if (toastService) {
    toastService.info(INVITE_SUCCESS_MESSAGE, {
      key: 'confirmInviteToast',
      transient: true,
    });
  }
};

const INVITE_SUCCESS_MESSAGE = 'Your invite has been sent';
const INVITE_ERROR_MESSAGE = 'It looks like we could not send this invite right now.';

const BREADCRUMB_LABEL = 'Invite';

const ROUTES_PATH = 'invite';
const ROUTES = [
  {
    path: '',
    canActivate: [],
    component: DisplayCardsComponent,
    data: {
      breadcrumb: { skip: true },
      pageConfig: {
        fbPage: 'home',
        fbCategory: 'invite',
        fbEventName: 'invite_shown',
        pageTitleKey: 'INVITE_HALFSHEET_TITLE',
        pageCards: [
          {
            key: 'householdCard',
            ctaPath: `${ROUTES_PATH}/household/info`,
            titleKey: 'INVITE_HOUSEHOLD_MEMBER',
            textKey: 'INVITE_INTRO_TITLE',
          },
          {
            key: 'colleagueCard',
            ctaPath: `${ROUTES_PATH}/colleague/phone`,
            titleKey: 'INVITE_COLLEAGUE',
            textKey: 'REFERRAL_SUBTITLE',
          },
        ],

        pageHideBackButton: true,
      },
    },
  },
  {
    path: 'colleague',
    data: { breadcrumb: { label: 'Invite', routeInterceptor: () => `/${ROUTES_PATH}` } },
    children: [
      {
        path: 'phone',
        canActivate: [],
        component: DisplayFormComponent,
        data: {
          breadcrumb: 'Colleague',
          pageConfig: {
            fbPage: 'colleague',
            fbCategory: 'invite',
            fbEventName: 'invite_colleague',
            pageTitleKey: 'INVITE_COLLEAGUE_DETAILS_TITLE',
            pageSubTitleKey: 'INVITE_COLLEAGUE_DETAILS_DESC',
            pageCtaLabelKey: 'SEND_INVITE',
            pageCtaEventName: 'invite_colleague_tapped',
            pageFormControls: [
              { ...CONTROL_FNAME, errorTextKey: 'ERROR_COLLEAGUE_INVITE_FIRSTNAME_WEB' },
              {
                ...CONTROL_PHONE,
                errorTextKey: 'ERROR_COLLEAGUE_INVITE_PHONE_WEB',
                inputOptions: {
                  ...CONTROL_PHONE.inputOptions,
                  link: {
                    path: [ROUTES_PATH, 'colleague', 'email'],
                    textKey: 'INVITE_VIA_EMAIL',
                  },
                },
              },
            ],

            pageOnForwardCtaPath: [ROUTES_PATH],
            pageOnBackCtaPath: [ROUTES_PATH],

            processUpdater: (injector: Injector, form: FormGroup) => {
              const observable = new Observable<DisplayFormProcessResponse>((subscriber) => {
                const inviteService: InviteService = injector.get(InviteService);
                const fName = form.get(CONTROL_FNAME.key)?.value;
                const phone = libphonenumber.parseDigits(form.get(CONTROL_PHONE.key)?.value);

                inviteService
                  .sendSMS({
                    referredClientName: fName,
                    type: InviteType.SMS,
                    referralType: InviteReferralType.CLIENT,
                    sms: phone,
                  })
                  .subscribe(
                    () => {
                      sendConfirmToast(injector.get(ToastService));

                      subscriber.next({ status: 'success' });
                      subscriber.complete();
                    },
                    (_) => {
                      subscriber.next({ status: 'failed', errorMessage: INVITE_ERROR_MESSAGE });
                      subscriber.complete();
                    }
                  );
              });

              return observable;
            },
          },
        },
      },
      {
        path: 'email',
        canActivate: [],
        component: DisplayFormComponent,
        data: {
          breadcrumb: 'Colleague',
          pageConfig: {
            fbPage: 'colleague',
            fbCategory: 'invite',
            fbEventName: 'invite_colleague_email',
            pageTitleKey: 'INVITE_COLLEAGUE_DETAILS_TITLE',
            pageSubTitleKey: 'INVITE_COLLEAGUE_DETAILS_DESC',
            pageCtaLabelKey: 'SEND_INVITE',

            pageFormControls: [
              { ...CONTROL_FNAME, errorTextKEY: 'ERROR_COLLEAGUE_INVITE_FIRSTNAME_WEB' },
              {
                ...CONTROL_EMAIL,
                errorTextKey: 'ERROR_COLLEAGUE_INVITE_EMAIL_WEB',
                inputOptions: {
                  ...CONTROL_EMAIL.inputOptions,
                  link: {
                    path: [ROUTES_PATH, 'colleague', 'phone'],
                    textKEY: 'INVITE_VIA_SMS',
                  },
                },
              },
            ],

            pageOnForwardCtaPath: [ROUTES_PATH],
            pageOnBackCtaPath: [ROUTES_PATH, 'colleague', 'phone'],

            processUpdater: (injector: Injector, form: FormGroup) =>
              new Observable<DisplayFormProcessResponse>((subscriber) => {
                const inviteService: InviteService = injector.get(InviteService);
                const fName = form.get(CONTROL_FNAME.key)?.value;
                const email = form.get(CONTROL_EMAIL.key)?.value;

                inviteService
                  .sendEmail({
                    referredClientName: fName,
                    type: InviteType.EMAIL,
                    referralType: InviteReferralType.CLIENT,
                    email: email,
                  })
                  .subscribe(
                    () => {
                      sendConfirmToast(injector.get(ToastService));

                      subscriber.next({ status: 'success' });
                      subscriber.complete();
                    },
                    (_) => {
                      subscriber.next({ status: 'failed', errorMessage: INVITE_ERROR_MESSAGE });
                      subscriber.complete();
                    }
                  );
              }),
          },
        },
      },
    ],
  },
  {
    path: 'household',
    data: { breadcrumb: { label: 'Invite', routeInterceptor: () => `/${ROUTES_PATH}` } },
    canActivate: [HouseholdInviteGuard],
    children: [
      {
        path: 'info',
        canActivate: [],
        component: DisplayFormComponent,
        data: {
          breadcrumb: 'Household member',
          pageConfig: {
            fbPage: 'household',
            fbCategory: 'invite',
            fbEventName: 'invite_household',
            pageTitleKey: 'INVITE_TITLE',
            pageSubTitleKey: 'INVITE_INTRO_DESC',
            pageCtaLabelKey: 'BUTTON_CONTINUE',
            pageCtaEventName: 'invite_household_tapped',
            pageFormControls: [
              { ...CONTROL_FNAME, errorTextKey: 'ERROR_INVITE_FIRST_NAME' },
              { ...CONTROL_LNAME, errorTextKey: 'ERROR_INVITE_LAST_NAME' },
              { ...CONTROL_DOB, errorTextKey: 'ERROR_INVITE_DOB' },
              { ...CONTROL_LAST_FOUR, errorTextKey: 'ERROR_INVITE_SSN' },
            ],

            pageOnForwardCtaPath: [ROUTES_PATH, 'household', 'phone'],
            pageOnBackCtaPath: [ROUTES_PATH],

            processUpdater: (injector: Injector, form: FormGroup) =>
              new Observable<DisplayFormProcessResponse>((subscriber) => {
                const inviteService: InviteService = injector.get(InviteService);
                const householdInfo: InviteHouseholdCache = {
                  fName: form.get(CONTROL_FNAME.key)?.value || '',
                  lName: form.get(CONTROL_LNAME.key)?.value || '',
                  dateOfBirth: form.get(CONTROL_DOB.key)?.value || '',
                  lastFourSSN: form.get(CONTROL_LAST_FOUR.key)?.value || '',
                };

                inviteService.saveDependent(householdInfo).subscribe(
                  () => {
                    subscriber.next({ status: 'success' });
                    subscriber.complete();
                  },
                  (error) => {
                    let errorMessage = '';

                    if (error.response.data.statusCode === 409) {
                      errorMessage = error.response.data.errorMessage || '';
                    }

                    subscriber.next({ status: 'failed', errorMessage });
                    subscriber.complete();
                  }
                );
              }),
          },
        },
      },
      {
        path: 'phone',
        canActivate: [],
        component: DisplayFormComponent,
        data: {
          breadcrumb: 'Household member',
          pageConfig: {
            fbPage: 'household-phone',
            fbCategory: 'invite',
            fbEventName: 'invite_household_phone',
            pageTitleKey: 'INVITE_TITLE',
            pageSubTitleKey: 'ENTER_RECIPIENTS_PHONE',
            pageCtaLabelKey: 'SEND_INVITE',

            pageOnForwardCtaPath: [ROUTES_PATH],
            pageOnBackCtaPath: [ROUTES_PATH, 'household', 'info'],

            pageFormControls: [
              {
                ...CONTROL_PHONE,
                inputOptions: {
                  ...CONTROL_PHONE.inputOptions,
                  link: {
                    path: [ROUTES_PATH, 'household', 'email'],
                    textKey: 'INVITE_VIA_EMAIL',
                  },
                },
              },
            ],

            processUpdater: (injector: Injector, form: FormGroup) => {
              const observable = new Observable<DisplayFormProcessResponse>((subscriber) => {
                const inviteService: InviteService = injector.get(InviteService);
                InviteService.getPreCacheHousehold().then(
                  cachedInfo => {
                    const phone = libphonenumber.parseDigits(form.get(CONTROL_PHONE.key)?.value);

                    inviteService
                      .sendSMS({
                        referredClientName: cachedInfo.fName,
                        type: InviteType.SMS,
                        referralType: InviteReferralType.HOUSEHOLD,
                        sms: phone,
                      })
                      .subscribe(
                        () => {
                          sendConfirmToast(injector.get(ToastService));

                          subscriber.next({ status: 'success' });
                          subscriber.complete();
                        },
                        (error) => {
                          subscriber.next({ status: 'failed', errorMessage: INVITE_ERROR_MESSAGE });
                          subscriber.complete();
                        }
                      );
                  }
                )
              });

              return observable;
            },
          },
        },
      },
      {
        path: 'email',
        canActivate: [],
        component: DisplayFormComponent,
        data: {
          breadcrumb: 'Household member',
          pageConfig: {
            fbPage: 'household-phone',
            fbCategory: 'invite',
            fbEventName: 'invite_household_email',
            pageTitleKey: 'INVITE_TITLE',
            pageSubTitleKey: 'ENTER_RECIPIENTS_EMAIL',
            pageCtaLabelKey: 'SEND_INVITE',

            pageOnForwardCtaPath: [ROUTES_PATH],
            pageOnBackCtaPath: [ROUTES_PATH, 'household', 'phone'],

            pageFormControls: [
              {
                ...CONTROL_EMAIL,
                inputOptions: {
                  ...CONTROL_EMAIL.inputOptions,
                  link: {
                    path: [ROUTES_PATH, 'household', 'phone'],
                    textKey: 'INVITE_VIA_SMS',
                  },
                },
              },
            ],

            processUpdater: (injector: Injector, form: FormGroup) => {
              const observable = new Observable<DisplayFormProcessResponse>((subscriber) => {
                const inviteService: InviteService = injector.get(InviteService);
                InviteService.getPreCacheHousehold().then(
                  cachedInfo => {
                    const email = form.get(CONTROL_EMAIL.key)?.value;

                    inviteService
                      .sendEmail({
                        referredClientName: cachedInfo.fName,
                        type: InviteType.EMAIL,
                        referralType: InviteReferralType.HOUSEHOLD,
                        email: email,
                      })
                      .subscribe(
                        () => {
                          sendConfirmToast(injector.get(ToastService));

                          subscriber.next({ status: 'success' });
                          subscriber.complete();
                        },
                        (error) => {
                          subscriber.next({ status: 'failed', errorMessage: INVITE_ERROR_MESSAGE });
                          subscriber.complete();
                        }
                      );
                  }
                )
              });

              return observable;
            },
          },
        },
      },
    ],
  },
];

export { BREADCRUMB_LABEL, ROUTES_PATH, ROUTES };
